body {
  text-align: center;
  margin-top: 50px;
  font-family: Arial, sans-serif;
}

button {
  color: #fff;
  cursor: pointer;
  background-color: #5416b2;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 20px;
}

#avatar {
  z-index: -1;
  width: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#badge {
  border: 2px solid #c49cff;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  max-height: 600px;
}

#imgInput {
  display: none;
}

#dropZone {
  color: #5416b2;
  text-align: center;
  border: 2px dashed #ccc;
  border-radius: 10px;
  max-width: 600px;
  height: 100px;
  margin: 20px auto;
  line-height: 100px;
}

#dropZone:hover, #dropZone.highlight {
  cursor: pointer;
  border: 3px dashed #5416b2;
}

#capture {
  width: 100%;
  max-width: 600px;
  max-height: 600px;
  margin: 20px auto;
  position: relative;
}
/*# sourceMappingURL=index.cf2abd5a.css.map */
